// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*

Monokai Sublime style. Derived from Monokai by noformnocontent http://nn.mit-license.org/

*/

.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  background: #191e3a;
}

.hljs,
.hljs-tag,
.hljs-subst {
  color: #f8f8f2;
}

.hljs-strong,
.hljs-emphasis {
  color: #a8a8a2;
}

.hljs-bullet,
.hljs-quote,
.hljs-number,
.hljs-regexp,
.hljs-literal,
.hljs-link {
  color: #ae81ff;
}

.hljs-code,
.hljs-title,
.hljs-section,
.hljs-selector-class {
  color: #a6e22e;
}

.hljs-strong {
  font-weight: bold;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-name,
.hljs-attr {
  color: #f92672;
}

.hljs-symbol,
.hljs-attribute {
  color: #66d9ef;
}

.hljs-params,
.hljs-class .hljs-title {
  color: #f8f8f2;
}

.hljs-string,
.hljs-type,
.hljs-built_in,
.hljs-builtin-name,
.hljs-selector-id,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-addition,
.hljs-variable,
.hljs-template-variable {
  color: #e6db74;
}

.hljs-comment,
.hljs-deletion,
.hljs-meta {
  color: #75715e;
}
`, "",{"version":3,"sources":["webpack://./src/plugins/highlight/styles/monokai-sublime.css"],"names":[],"mappings":"AAAA;;;;CAIC;;AAED;EACE,cAAc;EACd,gBAAgB;EAChB,cAAc;EACd,mBAAmB;AACrB;;AAEA;;;EAGE,cAAc;AAChB;;AAEA;;EAEE,cAAc;AAChB;;AAEA;;;;;;EAME,cAAc;AAChB;;AAEA;;;;EAIE,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;;;;EAIE,cAAc;AAChB;;AAEA;;EAEE,cAAc;AAChB;;AAEA;;EAEE,cAAc;AAChB;;AAEA;;;;;;;;;;EAUE,cAAc;AAChB;;AAEA;;;EAGE,cAAc;AAChB","sourcesContent":["/*\r\n\r\nMonokai Sublime style. Derived from Monokai by noformnocontent http://nn.mit-license.org/\r\n\r\n*/\r\n\r\n.hljs {\r\n  display: block;\r\n  overflow-x: auto;\r\n  padding: 0.5em;\r\n  background: #191e3a;\r\n}\r\n\r\n.hljs,\r\n.hljs-tag,\r\n.hljs-subst {\r\n  color: #f8f8f2;\r\n}\r\n\r\n.hljs-strong,\r\n.hljs-emphasis {\r\n  color: #a8a8a2;\r\n}\r\n\r\n.hljs-bullet,\r\n.hljs-quote,\r\n.hljs-number,\r\n.hljs-regexp,\r\n.hljs-literal,\r\n.hljs-link {\r\n  color: #ae81ff;\r\n}\r\n\r\n.hljs-code,\r\n.hljs-title,\r\n.hljs-section,\r\n.hljs-selector-class {\r\n  color: #a6e22e;\r\n}\r\n\r\n.hljs-strong {\r\n  font-weight: bold;\r\n}\r\n\r\n.hljs-emphasis {\r\n  font-style: italic;\r\n}\r\n\r\n.hljs-keyword,\r\n.hljs-selector-tag,\r\n.hljs-name,\r\n.hljs-attr {\r\n  color: #f92672;\r\n}\r\n\r\n.hljs-symbol,\r\n.hljs-attribute {\r\n  color: #66d9ef;\r\n}\r\n\r\n.hljs-params,\r\n.hljs-class .hljs-title {\r\n  color: #f8f8f2;\r\n}\r\n\r\n.hljs-string,\r\n.hljs-type,\r\n.hljs-built_in,\r\n.hljs-builtin-name,\r\n.hljs-selector-id,\r\n.hljs-selector-attr,\r\n.hljs-selector-pseudo,\r\n.hljs-addition,\r\n.hljs-variable,\r\n.hljs-template-variable {\r\n  color: #e6db74;\r\n}\r\n\r\n.hljs-comment,\r\n.hljs-deletion,\r\n.hljs-meta {\r\n  color: #75715e;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
